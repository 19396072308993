import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { GetControlDocument } from "../../../generated";
import ComplianceStatus from "../../common/ComplianceStatus";
import { FC, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import StyledTableCell from "../../common/StyledTableCell";
import AwsSvg from "../../../assets/aws-icon";
import FindingsDialog from "./FindingsDialog";
import LoadingButton from "@mui/lab/LoadingButton";

interface SecHubControl{
  controlname: string;
  controldescription: string;
  compliancestatus: string;
}

type RowQuestion = {
  frameworkControl: string;
  frameworkControlDescription: string;
  complianceStatus: string;
  secHubControls: Array<SecHubControl>;
};

interface TechnicalControlProps {
  index: number;
  technicalReviewArn: string;
  row: RowQuestion;
  connectedAccounts: Array<ConnectedAccount>;
}

type FindingRow = {
  id: string;
  awsAccountId: number;
  name: string;
  complianceStatus: string;
  title: string;
  findingId: string;
  serviceID: string;
}
/**
 * @param control sec hub control
 * @param technicalReviewArn arn to be assessed
 * @param connectedAccounts connected accounts under 
 * technicalreviewarn account
 * @returns sec hub controls that match framework selected
 */
const SecHubControls:  FC<any> = ({
  control,
  technicalReviewArn,
  connectedAccounts,
}:{
  control: SecHubControl;
  technicalReviewArn: string;
  connectedAccounts: Array<ConnectedAccount>;
}) => {
  const [openFindings, setOpenFindings] = useState(false);
  /**
   * Close Findings Modal
   */
  const handleClose = () => {
    setOpenFindings(false);
  };
  const [ GetControl, {loading} ] = useLazyQuery(GetControlDocument);
  
  const [findings, setFindings] = useState<Array<FindingRow>>([]);
  /**
   * @param control sechub control
   * Get Sechub Control Findings
   */
  const GetControlFindings = async (control: SecHubControl) => {
    const response = await GetControl({
      variables: {
        arn: technicalReviewArn,
        controlname: control.controlname,
        connectedAccounts,
      },
    });

    const findingMap = new Map();
    const findings = response.data?.getControl?.findings;
    findings.forEach((finding: any) => {
      const key = finding.Id;
      const findingId = finding.Id.match(/finding\/(.+)/);
      if (!findingMap.has(key)) {
        findingMap.set(key, {
          id: finding.Id,
          awsAccountId: finding.AwsAccountId,
          name: finding?.Resources ? finding?.Resources[0]?.Name : "",
          complianceStatus: finding.Compliance.Status,
          title: finding.Title,
          findingId: findingId[1],
          serviceID: finding?.Resources ? finding?.Resources[0]?.Id : "",
        })
      }
    });
    const data = Array.from(findingMap.values()).map(item => ({
      ...item,
    }));
    setFindings(data);
    setOpenFindings(true);
  };
  return(
    <TableRow key={control.controlname}>
      <StyledTableCell colSpan={1} />
      <StyledTableCell key={'f-1'} colSpan={4} sx={{ justify: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ComplianceStatus value={control.compliancestatus} />
        </Box>
      </StyledTableCell>
      <StyledTableCell key={'f-2'} colSpan={3} sx={{ paddingLeft: 0 }}>
        <Table>
          <TableBody sx={{ pl: 0 }}>
            <TableRow key={`${control.controlname}-2`}>
              <StyledTableCell
                colSpan={3}
                sx={{
                  borderBottom: 0,
                  padding: 0.25,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"} sx={{ width: 280 }}>
                  {control.controlname}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableCell>
      <StyledTableCell key={'f-3'} colSpan={3} sx={{ verticalAlign: "top" }}>
        <LoadingButton
          variant="outlined"
          sx={{ width: "100%" }}
          startIcon={<AwsSvg/>}
          loading={loading}
          onClick={() => GetControlFindings(control)}
          loadingIndicator={
            <CircularProgress color={"secondary"} size={22} />
          }
        >
          <Typography fontSize={"10px"} sx={{ textAlign: "left" }}>
            View Findings
          </Typography>
        </LoadingButton>
        <FindingsDialog
          open={openFindings}
          handleClose={handleClose}
          control={control}
          findings={findings}
        />
      </StyledTableCell>
      <StyledTableCell colSpan={1} />
    </TableRow>
  )
};

/**
 * Display Technical Control information
 * @param index index value
 * @param technicalReviewArn technical reveiew arn that was selected when creating the framework
 * @param row row information
 * @param connectedAccounts state of connectedAccounts information
 * @returns row information of technical control
 */
export default function TechnicalControl({
  index,
  technicalReviewArn,
  row,
  connectedAccounts,
}: TechnicalControlProps) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        key={`${row.frameworkControl}-tc`}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.frameworkControl}
        </TableCell>
        <TableCell component="th" scope="row" width={"75%"}>
          {row.frameworkControlDescription}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <ComplianceStatus value={row.complianceStatus} />
        </TableCell>
        <TableCell width={"5%"}>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell colSpan={1}/>
                  <TableCell align="center" colSpan={4}>Compliance Status</TableCell>
                  <TableCell colSpan={3}>AWS Control ID</TableCell>
                  <TableCell align="center" colSpan={3}>View Findings</TableCell>
                  <TableCell colSpan={1}/>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                row.secHubControls.map((control: any) => {
                  return (
                    <SecHubControls
                      key={control.controlname}
                      control={control}
                      technicalReviewArn={technicalReviewArn}
                      connectedAccounts={connectedAccounts}
                    />
                  );
                })
              }
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
