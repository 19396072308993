import Typography from "@mui/material/Typography";
import {
  Grid,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useCreateExecutiveSummaryMutation } from "../../../generated";
import useAccount from "../../../hooks/useAccount";
import useSnackBars from "../../../hooks/useSnackbar";
import { LoadingButton } from "@mui/lab";

const validationSchema = Yup.object({
  arn: Yup.string().required("ARN is required"),
  //logo: Yup.string().url("Invalid URL"),
  connectedAccounts: Yup.array().required("Connected accounts are required"),
  costPerMinute: Yup.number().min(0, "Hourly rate must be greater than 0"),
  companyName: Yup.string().required("Summary title is required"),
  name: Yup.string().required("Partner name is required"),
  email: Yup.string()
    .required("Partner email is required")
    .email("Invalid email format"),
});

export default function ExecutiveSummary() {
  const { accounts } = useAccount();
  const [createSummary, { loading }] = useCreateExecutiveSummaryMutation();
  const { setAlerts } = useSnackBars();

  const formik = useFormik({
    initialValues: {
      arn: "",
      logo: "",
      connectedAccounts: [],
      costPerMinute: 50,
      companyName: "",
      name: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log(values);
        const response = await createSummary({
          variables: values,
          context: {
            apiName: "well_architected",
          },
          notifyOnNetworkStatusChange: true,
        });
        if (response?.data?.createExecutiveSummary?.result === "PENDING") {
          setAlerts([
            {
              severity: "success",
              msg: "You will receive an email with the executive summary shortly.",
            },
          ]);
        } else {
          setAlerts([
            {
              severity: "error",
              msg: "Error submitting request. Please try again.",
            },
          ]);
        }
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    },
  });

  return (
    <Grid container spacing={3} justifyContent="center" sx={{ my: 4 }}>
      <Grid item xs={12} md={8} lg={6}>
        <Container
          maxWidth="sm"
          sx={{
            p: 3,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: "background.paper",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            textAlign="center"
            fontWeight={600}
          >
            Request an Executive Summary Report
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel sx={{ backgroundColor: "white", p: "1px" }}>
                AWS Technical Review Account
              </InputLabel>
              <Select
                required
                id="arn"
                name="arn"
                value={formik.values.arn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.arn && Boolean(formik.errors.arn)}
              >
                {accounts.map((a) => {
                  let splits = a.arn.split(":");
                  return (
                    <MenuItem
                      key={`${a.accessnickname} - ${splits[4]}`}
                      value={a.arn}
                      disabled={a.accounttype === "LITE"}
                    >
                      {`${a.accessnickname} - ${splits[4]}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <TextField
              fullWidth
              margin="normal"
              label="Logo URL"
              name="logo"
              value={formik.values.logo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.logo && Boolean(formik.errors.logo)}
              helperText={formik.touched.logo && formik.errors.logo}
            /> */}
            <TextField
              fullWidth
              margin="normal"
              label="Hourly Rate"
              name="costPerMinute"
              type="number"
              value={formik.values.costPerMinute}
              onChange={() => {
                formik.setFieldValue(
                  "costPerMinute",
                  formik.values.costPerMinute
                );
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.costPerMinute &&
                Boolean(formik.errors.costPerMinute)
              }
              helperText={
                formik.touched.costPerMinute && formik.errors.costPerMinute
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Summary Title"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Partner Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Partner Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, py: 1.5 }}
              disabled={formik.isSubmitting || loading}
              loading={formik.isSubmitting || loading}
            >
              Submit
            </LoadingButton>
          </form>
        </Container>
      </Grid>
    </Grid>
  );
}
