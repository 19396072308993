import { Box, CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { useListSecurityControlsQuery } from "../../../hooks/useListSecurityControlsQuery";
import { useCallback, useEffect, useState } from "react";
import Loader from "../../common/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import TechnicalControl from "./TechnicalControl";
import StyledTableCell from "../../common/StyledTableCell";

interface TechnicalControlsListProps {
  frameworkSelection: string;
  technicalReviewArn: string;
  connectedAccounts: Array<ConnectedAccount>;
  setTechnicalControlsData: any;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
  changeTab: (index: number) => void;
}

interface SecHubControl{
  controlname: string;
  controldescription: string;
  compliancestatus: string;
}

type RowQuestion = {
  frameworkControl: string;
  frameworkControlDescription: string;
  complianceStatus: string;
  secHubControls: Array<SecHubControl>;
};

/**
 * File Receives the framework that was selected
 * Displays all controls associated with the framework
 * including disruptive and non disruptive
 * @param technicalReviewArn technical reveiew arn that was selected when creating the framework
 * @returns Entire List of Technical Controls
 */
export default function TechnicalControlsList({
  frameworkSelection,
  technicalReviewArn,
  connectedAccounts,
  setTechnicalControlsData,
  setUnlockSteps,
  changeTab,
}: TechnicalControlsListProps){
  const { controls, loading } = useListSecurityControlsQuery({
    arn: technicalReviewArn,
    connectedAccounts,
  });

  const [rows, setRows] = useState<Array<RowQuestion>>([]);

  //Handle that unlocks step 2
  const handleUnlockSteps = useCallback(() => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false, // Lock step 2
      step3: true, // Unlock Step 3
    }));
  }, [setUnlockSteps]);

  //Move to Step 3
  const handleSubmit = useCallback(() => {
    handleUnlockSteps();
    changeTab(2);
  }, [handleUnlockSteps, changeTab]);  

  useEffect(() => {
    if (loading) return;
  
    const filteredControls = controls.filter((control: any) => 
      Array.isArray(control.customStandard) &&
      control.customStandard.includes(frameworkSelection)
    );
    const frameworkMap = new Map();
    filteredControls.forEach((control: any) => {
      const associatedControls = (control.controlAssociations || []).filter(
        (assoc: any) => assoc.standard === frameworkSelection
      );
      associatedControls.forEach((assoc: any) => {
        const key = assoc.control; // Framework Control
  
        if (!frameworkMap.has(key)) {
          frameworkMap.set(key, {
            frameworkControl: assoc.control,
            frameworkControlDescription: assoc.description,
            secHubControls: [], // Now an array of objects
            complianceStatus: [], // Store compliance status for evaluation
          });
        }
  
        const entry = frameworkMap.get(key);
        entry.secHubControls.push({
          controlname: control.controlname,
          controldescription: control.controldescription,
          compliancestatus: control.compliancestatus,
        });
        entry.complianceStatus.push(control.compliancestatus);
      });
    });
  
    // Convert Map to Array and determine final compliance status
    const data = Array.from(frameworkMap.values()).map(item => ({
      ...item,
      complianceStatus: item.complianceStatus.every((status: string) => status === "PASSED" || status === "UNKNOWN")
        ? "PASSED"
        : "FAILED",
    }))
    .sort((a, b) => a.frameworkControl.localeCompare(b.frameworkControl)); // Sort by frameworkControl
    setRows(data);
    setTechnicalControlsData(filteredControls);
  }, [loading, controls, frameworkSelection]);
  
  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Control ID</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell align="center">Compliance</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <Loader/>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <TechnicalControl
                  key={row.frameworkControl}
                  index={index}
                  technicalReviewArn={technicalReviewArn}
                  row={row}
                  connectedAccounts={connectedAccounts}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item md={10} />
        <Grid item md={2}>
          <Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmit}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  ) 
}