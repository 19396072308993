import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { DownloadQuestionnaireReportDocument } from "../../../generated";
import { Alert, CircularProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import OnResult from "../../subscriptions/OnResult";

interface DownloadQuestionnaireReportProps {
	arn: string;
	framework: string;
	answers: Array<{
    id: string;
    selection: string;
  }>;
}

/**
 * @param arn receives arn
 * @param framework sends framework selected
 * @param answers sends array of answers selected
 * @returns Download Questionnaire Report
 */
export default function DownloadQuestionnaireReport({
	arn,
	framework,
	answers
}: DownloadQuestionnaireReportProps){
	const [activeSpinner, setActiveSpinner] = useState(false);
	const [stateMachineId, setStateMachineId] = useState(null);
	const [subscriptionResult, setSubscriptionResult] = useState(null);
	const [disabled, setDisabled] = useState(false);
	
	/**
	 * @param url url to download
	 * @param filename filename of excel file
	 * Download Excel File
	 */
	const onDownloadClick = (url:string, filename:string) => {
		const element = document.createElement("a");
		element.href = url;
		element.download = filename;
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	/**
	 * @param result get url and filename from backend
	 * Call onDownloadClick to download excel file
	 */
	function Complete(result:any){
		const obj = JSON.parse(result);
		onDownloadClick(obj.url, obj.filename);
		setSubscriptionResult(null);
		setActiveSpinner(false);
		setDisabled(false);
	}

	const [ downloadQuestionnaireReport, { error }] = useLazyQuery(DownloadQuestionnaireReportDocument, { 
		onCompleted: (QuestionnaireReportData: { downloadQuestionnaireReport: { id: any; status: any; result: string; }; }) => {
			setStateMachineId(QuestionnaireReportData?.downloadQuestionnaireReport?.id)
			if(QuestionnaireReportData?.downloadQuestionnaireReport?.status === "COMPLETE"){
				Complete(QuestionnaireReportData?.downloadQuestionnaireReport?.result);
			}
		} 
	});

	/**
	 * Call Download Questionnaire Report API
	 */
	function Download(){
		setActiveSpinner(true);
		setDisabled(true);
		downloadQuestionnaireReport({
			variables: { 
				arn,
				framework,
				answers,
			},
			context: {
				apiName: "well_architected"
			},
		});
	}

	useEffect(() => {
		if(subscriptionResult !== null){
			Complete(subscriptionResult);
		}
	}, [subscriptionResult]);

	return (
		<>
		<LoadingButton
			color="primary"
			variant="outlined"
			type="submit"
			disabled={disabled}
			loading={activeSpinner}
			onClick={() => Download()}
			sx={{
				m: 0,
				ml: 1,
				mb: 1,
				width: "50%",
				flexGrow: 0,
				fontWeight: 600,
			}}
			loadingIndicator={
				<CircularProgress color={"secondary"} size={22} />
			} 
		>
			Download
		</LoadingButton>
		{
			stateMachineId !== null ? 
			<OnResult 
				statemachineid={stateMachineId} 
				setSubscriptionResult={setSubscriptionResult} 
				setStateMachineId={setStateMachineId}
			/> 
			: null
		}
		{error ? <Alert severity="error">Self-Assessment download failed</Alert>: null}
		</>
	)
}