import { Dialog, DialogTitle, DialogContent, Typography, Grid } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC } from "react";

interface SecHubControl{
  controlname: string;
  controldescription: string;
  compliancestatus: string;
}

type FindingRow = {
  id: string;
  awsAccountId: number;
  name: string;
  complianceStatus: string;
  title: string;
  findingId: string;
  serviceID: string;
}

/**
 * 
 * @param open boolean to open findings modal
 * @param handleClose to close modal
 * @param control sechub control
 * @param findings sechub control findings
 * @returns display findings of sechub control
 */
const FindingsDialog: FC<{
  open: boolean;
  handleClose: () => void;
  control: SecHubControl;
  findings: Array<FindingRow>;
}> = ({
  open,
  handleClose,
  control,
  findings,
}: {
  open: boolean;
  handleClose: () => void;
  control: SecHubControl;
  findings: FindingRow[];
}) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "awsAccountId",
      headerName: "AWS Account ID",
      flex: 1
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "serviceID", headerName: "Service ID", flex: 2 },
    { field: "findingId", headerName: "Finding ID", flex: 2 },
    { 
      field: "complianceStatus",
      headerName: "Compliance Status",
      flex: 1
    },
  ];

  return (
    <Dialog open={open} fullWidth maxWidth={"xl"} onClose={handleClose}>
      <DialogTitle>{control.controlname} Findings in your AWS Account</DialogTitle>
      <DialogContent>
        <Grid container sx={{ m: 1 }}>
          <Grid item md={2}>
            <Typography fontSize={"14px"}>Control ID :</Typography>
          </Grid>
          <Grid item md={10}>
            <Typography fontSize={"14px"}>
              {control.controlname ?? "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ m: 1 }}>
          <Grid item md={2}>
            <Typography fontSize={"14px"}>Control Description:</Typography>
          </Grid>
          <Grid item md={10}>
            <Typography fontSize={"14px"}>
              {control.controldescription ?? "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ m: 1 }}>
          <Grid item md={2}>
            <Typography fontSize={"14px"}>
              Finding Title:
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Typography
              fontSize={"14px"}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {findings.length > 0 ? (
                findings?.[0]?.title
              ) : (
                "N/A"
              )}
            </Typography>
          </Grid>
        </Grid>
        {findings.length > 0 ? (
          <DataGrid 
            rows={findings
              .map((finding: FindingRow, index) => {
                return {
                  id: index + 1,
                  awsAccountId: finding.awsAccountId,
                  name: finding.name,
                  serviceID: finding.serviceID,
                  findingId: finding.findingId,
                  complianceStatus: finding.complianceStatus,
                };
              })
            }
            density="compact"
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5]}
          />
        ) : (
          <Typography sx={{ textAlign: "center", mt: 2, color: "gray" }}>
            No findings available
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
};

export default FindingsDialog;