import {
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { GetControlDocument } from "../../../generated";
import ConnectedAccount from "../../../models/ConnectedAccount";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ComplianceStatus from "../../common/ComplianceStatus";
import Loader from "../../common/Loader";
import StyledTableCell from "../../common/StyledTableCell";
import { useLazyQuery } from "@apollo/client";
import FindingsDialog from "./FindingsDialog";

interface SecHubControl{
  controlname: string;
  controldescription: string;
  compliancestatus: string;
}

interface FrameworkControl{
  frameworkControl: string;
  frameworkControlDescription: string;
}

type RowQuestion = {
  controlname: string;
  controldescription: string;
  compliancestatus: string;
  remediationsteps: string;
  remediatestatus: string;
  intrusive: string;
  frameworkControls: Array<FrameworkControl>;
};

type FindingRow = {
  id: string;
  awsAccountId: number;
  name: string;
  complianceStatus: string;
  title: string;
  findingId: string;
  serviceID: string;
}

interface NonDisruptiveQuestionsProps {
  technicalReviewArn: string;
  index: number;
  accounttype: string;
  rows: RowQuestion[];
  row: RowQuestion;
  rowSelection: any;
  setRowSelection: any;
  selectedControls: any[]; // Array of controls currently selected in the parent component
  updateSelectedControls: (control: any, isSelected: boolean) => void; // Function to update the selected controls state
  failedRows: string[];
  setFailedRows: React.Dispatch<React.SetStateAction<string[]>>;
  switchBehavior: any;
  connectedAccounts: Array<ConnectedAccount>;
  activeSpinner: boolean;
}

/**
 * Display Row Information of NonDisruptive Question and any findings for the row
 * @returns non disrutpive question row info
 */
export default function NonDisruptiveQuestions({
  index,
  technicalReviewArn,
  accounttype,
  rows,
  row,
  rowSelection,
  setRowSelection,
  selectedControls,
  updateSelectedControls,
  failedRows,
  setFailedRows,
  switchBehavior,
  connectedAccounts,
  activeSpinner,
}: NonDisruptiveQuestionsProps) {
  let failedFinding = false;
  const [openFindings, setOpenFindings] = useState(false);
  /**
   * Close Findings Modal
   */
  const handleClose = () => {
    setOpenFindings(false);
  };
  const [ GetControl, { loading } ] = useLazyQuery(GetControlDocument);
  
  const [findings, setFindings] = useState<Array<FindingRow>>([]);
  /**
   * @param control sechub control
   * Get sechub control findings
   */
  const GetControlFindings = async (control: SecHubControl) => {
    const response = await GetControl({
      variables: {
        arn: technicalReviewArn,
        controlname: control.controlname,
        connectedAccounts,
      },
    });

    const findingMap = new Map();
    const findings = response.data?.getControl?.findings;
    findings.forEach((finding: any) => {
      if (finding.Compliance.Status === "FAILED") {
        failedFinding = true;
      }
      const key = finding.Id;
      const findingId = finding.Id.match(/finding\/(.+)/);
      if (!findingMap.has(key)) {
        findingMap.set(key, {
          id: finding.Id,
          awsAccountId: finding.AwsAccountId,
          name: finding?.Resources ? finding?.Resources[0]?.Name : "",
          complianceStatus: finding.Compliance.Status,
          title: finding.Title,
          findingId: findingId[1],
          serviceID: finding?.Resources ? finding?.Resources[0]?.Id : "",
        })
      }
    });
    const data = Array.from(findingMap.values()).map(item => ({
      ...item,
    }));
    setFindings(data);
    setOpenFindings(true);
  };

  //MAR - Multi Automated Remediation
  //RowSelection displays all the rows that have been selected in the MAR Modal
  //RowNames keeps track of which rows were selected and passes it to the updateBestPracticeMutation
  //SetFailedRows adds controls that have a failed finding to the array and passes it to the MAR Modal
  const handleQuestionAnswer = useCallback(
    (row: any) => {
      if (rowSelection.includes(row)) {
        setRowSelection(
          rowSelection.filter((r: any) => r.controlname !== row.controlname)
        );
        if (failedFinding) {
          setFailedRows(failedRows.filter((n: any) => n !== row.controlname));
        }
        updateSelectedControls(row, false); // Remove from parent state
      } else {
        setRowSelection([...rowSelection, row]);
        if (failedFinding) {
          setFailedRows([...failedRows, row.controlname]);
        }
        updateSelectedControls(row, true); // Add to parent state
      }
    },
    [
      rowSelection,
      setRowSelection,
      failedFinding,
      setFailedRows,
      updateSelectedControls,
      failedRows,
    ]
  );

  //This useEffect triggers if Select All Recommendations? is selected
  useEffect(() => {
    if (switchBehavior === "All") {
      setRowSelection(rows);
      rows.forEach((row: any) => updateSelectedControls(row, true));
      if (failedFinding) {
        setFailedRows((i) => [...i, row.controlname]);
      }
    } else {
      setRowSelection([]);
      rows.forEach((row: any) => {
        const isPreSelected = row.remediatestatus === "ENABLED";
        updateSelectedControls(row, isPreSelected);
      });
      if (failedFinding) {
        setFailedRows([]);
      }
    }
  }, [switchBehavior]);

  return (
    <TableRow
      key={`${row.controlname}-ndq`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell component="th" scope="row">
        <Table>
          <TableBody>
          {
            row.frameworkControls.map((control: any) => {
              return (
                <TableRow key={`${control.frameworkControl}-1`}>
                  <StyledTableCell
                    colSpan={3}
                    sx={{
                      borderBottom: 0,
                      padding: 0.5,
                    }}
                  >
                    <Typography fontSize={"14px"} sx={{ width: 100 }}>
                      {control.frameworkControl}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={3}
                    sx={{
                      borderBottom: 0,
                      padding: 0.5,
                      verticalAlign: "top",
                    }}
                  >
                    <Tooltip title={control.frameworkControlDescription} arrow>
                      <Typography
                        fontSize={"14px"}
                        sx={{
                          width: 280,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {control.frameworkControlDescription}
                      </Typography>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              );
            })
          }
          </TableBody>
        </Table>
      </TableCell>
      <TableCell component="th" scope="row">
        {row.controlname}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <ComplianceStatus value={row.compliancestatus} />
      </TableCell>
      {loading ? (
        <TableCell align="center" width={"100%"}>
          <Loader />
        </TableCell>
      ) : (
        <TableCell align="center" width={"100%"}>
          {accounttype === "AUTOMATED" ? (
            <Switch
              checked={
                selectedControls.some(
                  (control) => control.controlname === row.controlname
                ) || row.remediatestatus === "ENABLED"
              } // Set checked state based on switchBehavior
              disabled={
                row.remediatestatus === "ENABLED" || activeSpinner
              }
              onClick={() => {
                handleQuestionAnswer(row);
              }}
              color="success"
            />
          ) : (
            <Typography>N/A - Read Only</Typography>
          )}
        </TableCell>
      )}
      <TableCell width={"5%"}>
        <IconButton color="secondary" onClick={() => GetControlFindings(row)}>
          <InfoOutlinedIcon />
        </IconButton>
        <FindingsDialog
          open={openFindings}
          handleClose={handleClose}
          control={row}
          findings={findings}
        />
      </TableCell>
    </TableRow>
  );
}
