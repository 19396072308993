import { Box, CircularProgress, Container, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ConnectedAccount from "../../../models/ConnectedAccount";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../common/Loader";
import { useCallback, useEffect, useRef, useState } from "react";
import { useListSecurityControlsQuery } from "../../../hooks/useListSecurityControlsQuery";
import NonDisruptiveQuestions from "./NonDisruptiveQuestions";
import useSnackBars from "../../../hooks/useSnackbar";
import MultiAutomatedRemediationDialog from "./MultiAutomatedRemediationDialog";
import StyledTableCell from "../../common/StyledTableCell";

interface NonDisruptiveProps {
  targetAccountType: string;
  changeTab: (index: number) => void;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
  frameworkSelection: string;
  technicalReviewArn: string;
  connectedAccounts: Array<ConnectedAccount>;
  selectedControls: any[]; // Array of controls currently selected in the parent component
  updateSelectedControls: (control: any, isSelected: boolean) => void; // Function to update the selected controls state
}

interface FrameworkControl{
  frameworkControl: string;
  frameworkControlDescription: string;
}

type RowQuestion = {
  controlname: string;
  controldescription: string;
  compliancestatus: string;
  remediationsteps: string;
  remediatestatus: string;
  intrusive: string;
  frameworkControls: Array<FrameworkControl>;
};

/**
 * All Non Disruptive Recommendations of standard selected
 * @param targetAccountType returns if account is AUTOMATED or READ_ONLY
 * @param changeTab tab to switch to after this page is completed
 * @param setUnlockSteps which step to unlock and lock in the tabs
 * @param frameworkSelection standard selected
 * @param technicalReviewArn technical reveiew arn that was selected when creating the framework
 * @param connectedAccounts state of connectedAccounts information
 * @param selectedControls state to keep track of controls that are selected on this page
 * @param updateSelectedControls called when a control is selected to be remediated from this page
 * @returns all non disruptive recommendations for standard selected
 */
export default function NonDisruptiveRecommendations({
  targetAccountType,
  changeTab,
  setUnlockSteps,
  frameworkSelection,
  technicalReviewArn,
  connectedAccounts,
  selectedControls,
  updateSelectedControls,
}: NonDisruptiveProps){
  const { setAlerts } = useSnackBars();
  
  const { controls, loading, updateData } = useListSecurityControlsQuery({
    arn: technicalReviewArn,
    connectedAccounts,
  });

  const [rows, setRows] = useState<Array<RowQuestion>>([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [failedRows, setFailedRows] = useState<string[]>([]);
  const [switchBehavior, setSwitchBehavior] = useState("Off");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [noRemediation, setNoRemediation] = useState(true);
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const scrollRef = useRef<null | HTMLDivElement>(null);

  //Handle that unlocks step 2
  const handleUnlockSteps = useCallback(() => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step3: false, // Lock step 3
      step4: true, // Unlock step 4
    }));
  }, [setUnlockSteps]);

  //Open Multi Automted Remediation Modal
  const handleClickOpen = useCallback(() => {
    setOpen(true);
    setActiveSpinner(true);
  }, [setOpen, setActiveSpinner]);

  //Close Multi Automted Remediation Modal
  const handleClose = useCallback(() => {
    setOpen(false);
    setDisabled(false);
    setActiveSpinner(false);
  }, [setOpen, setDisabled, setActiveSpinner]);

  //Set switch to All to remediate all the controls instead of selecting individually
  const handleSwitch = useCallback(() => {
    setSwitchBehavior((prev) => (prev === "Off" ? "All" : "Off")); // Toggle between "All" and "Independent"
  }, [setSwitchBehavior]);

  //Multi Automated Remediation Confirm
  const handleConfirm = useCallback((
    status: boolean,
    controls: string[],
    msg: string
  ) => {
    if (status) {
      setOpen(false); //Closes the Multi Automated Remediation Modal after its initiated all the remediations
      updateData(controls, "remediatestatus", "ENABLED"); //this is done to ensure that when a milestone is created below it gets the latest controls compliance status
      setAlerts([
        {
          severity: "success",
          msg: "Best-practice Recommendations Successfully Enabled.",
        },
      ]);
      //switch to step 4
      handleUnlockSteps();
      changeTab(3);
    } else if (msg !== "") {
      setAlerts([
        {
          severity: "error",
          msg,
        },
      ]);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: `Best-practice Recommendations toggling failed for following controls ${controls.join(
            ", "
          )}`,
        },
      ]);
    }
    setOpen(false);
    setDisabled(false);
  }, [setOpen, setDisabled, handleUnlockSteps, changeTab, setAlerts, updateData]);

  //If AccountType is Read_Only or no controls are selected to be remediated move to Step 4 when Next is clicked
  //Otherwise open Multi Automated Remediation Dialog
  const handleSubmit = useCallback(() => {
    if (targetAccountType !== "AUTOMATED") {
      handleUnlockSteps();
      changeTab(3);
    } else {
      if (noRemediation) {
        handleUnlockSteps();
        changeTab(3);
      } else {
        setDisabled(true);
        handleClickOpen();
      }
    }
  }, [handleUnlockSteps, handleClickOpen, changeTab, setDisabled, targetAccountType, noRemediation]);

  useEffect(() => {
    //Controls in here are those that have the well-architected filter but aren't in the excel file that has wat best-practice mappings
    const donotdisplaycontrols: Array<string> = [];

    //Remove this if statement after we have fixed being able to remediate the below controls in a sub account
    if (
      technicalReviewArn.split(":")[4] !== connectedAccounts?.[0]?.accountIdRef 
      && connectedAccounts.length > 0
    ) {
      donotdisplaycontrols.push(
        "Macie.1",
        "GuardDuty.1",
        "CloudWatch.1",
        "CloudWatch.2",
        "CloudWatch.8",
        "CloudWatch.9",
        "CloudWatch.10",
        "CloudWatch.11",
        "CloudWatch.12",
        "CloudWatch.13",
        "CloudWatch.14"
      );
    }

    const filteredControls = controls.filter((control: any) => 
      Array.isArray(control?.customStandard) &&
      !donotdisplaycontrols.includes(control?.controlname) &&
      control?.intrusive === "NON-INTRUSIVE" &&
      control?.customStandard.length > 0 &&
      control.customStandard.includes(frameworkSelection)
    );

    const frameworkMap = new Map();
    filteredControls.forEach((control: any) => {
      const key = control.controlname; // AWS Control
      const associatedControls = (control.controlAssociations || []).filter(
        (assoc: any) => assoc.standard === frameworkSelection
      );
      associatedControls.forEach((assoc: any) => {
        if (!frameworkMap.has(key)) {
          frameworkMap.set(key, {
            controlname: control.controlname,
            controldescription: control.controldescription,
            compliancestatus: control.compliancestatus,
            remediationsteps: control.remediationsteps,
            remediatestatus: control.remediatestatus,
            intrusive: control.intrusive,
            frameworkControls: [], // Now an array of objects
            // complianceStatus: [], // Store compliance status for evaluation
          });
        }
  
        const entry = frameworkMap.get(key);
        entry.frameworkControls.push({
          frameworkControl: assoc.control,
          frameworkControlDescription: assoc.description,
        });
        // entry.complianceStatus.push(control.compliancestatus);
      });
    });
    // Convert Map to Array and determine final compliance status
    const data = Array.from(frameworkMap.values()).map(item => ({
      ...item,
    }))
    .sort((a, b) => a.controlname.localeCompare(b.controlname)); // Sort by frameworkControl
    setRows(data);
  }, [loading, controls, frameworkSelection]);

  useEffect(() => {
    if (connectedAccounts.length > 0) {
      setAccountIdRef(connectedAccounts?.[0]?.accountIdRef);
    } else {
      setAccountIdRef(technicalReviewArn.split(":")[4]);
    }
  }, [connectedAccounts]);

  useEffect(() => {
    //If Controls are selected to be remediated then setNoRemediation to false 
    //so that Multi Automated Remediation Modal opens
    if (rowSelection.length > 0) {
      setNoRemediation(false);
    } else {
      setNoRemediation(true);
    }
  }, [rowSelection]);
  
  useEffect(() => {
    if (targetAccountType !== "AUTOMATED" && rows.length !== 0) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [targetAccountType, rows]);

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
        <Grid item xs md={12} sx={{ pt: 2 }}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography fontWeight="bold" noWrap>
              Select All Recommendations?
            </Typography>
            <Typography fontWeight="bold" noWrap>
              &nbsp;
            </Typography>
            <Switch
              checked={switchBehavior === "All"} // Set checked state based on switchBehavior
              disabled={rows.length === 0 || activeSpinner || targetAccountType !== "AUTOMATED"}
              onChange={handleSwitch} // Handle switch toggling
              color="success"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs md={4} />
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Paper
            key={"3"}
            component="div"
            elevation={0}
            sx={{
              p: "2px 4px",
              mb: 2,
              display: "flex",
              alignItems: "right",
              width: 205,
              justifyContent: "center",
            }}
          >
            {disabled ? (
              <MultiAutomatedRemediationDialog
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                rows={rowSelection}
                failedRows={failedRows}
                technicalReviewArn={technicalReviewArn}
                accountRefId={accountIdRef}
              />
            ) : null}
          </Paper>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="center">Framework</StyledTableCell>
                <StyledTableCell>AWS Control ID</StyledTableCell>
                <StyledTableCell>Compliance Status</StyledTableCell>
                <StyledTableCell align="center">Select</StyledTableCell>
                <StyledTableCell>View Findings</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <Loader/>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <NonDisruptiveQuestions
                  key={row.controlname}
                  index={index}
                  technicalReviewArn={technicalReviewArn}
                  accounttype={targetAccountType}
                  rows={rows}
                  row={row}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  selectedControls={selectedControls}
                  updateSelectedControls={updateSelectedControls}
                  failedRows={failedRows}
                  setFailedRows={setFailedRows}
                  switchBehavior={switchBehavior}
                  connectedAccounts={connectedAccounts}
                  activeSpinner={activeSpinner}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      <Grid item md={10} />
      <Grid item md={2}>
        <Box ref={scrollRef}>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={disabled}
            loading={activeSpinner}
            onClick={handleSubmit}
            sx={{
              width: 157,
              height: 40,
              bgcolor: "secondary.main",
              "&:hover": {
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
              },
            }}
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
          >
            Next
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  </Container>
  );
}