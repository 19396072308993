import TableRow from "@mui/material/TableRow";
import { useCallback, useState } from "react";
import {
  GetUrlDocument,
} from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";
import { Box, Collapse, IconButton, Switch, Typography } from "@mui/material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useLazyQuery } from "@apollo/react-hooks";
import StyledTableCell from "../../common/StyledTableCell";

interface Row {
  question_id: string;
  question: string;
  answer_id: string;
  answers: string[];
  template: string;
  questionnaire_answer: string;
}

interface FrameworkQuestionProps {
  index: number;
  row: Row;
  answer: string; // Current answer for this question
  onAnswerChange: (questionId: string, answer: string) => void; // Function to handle answer changes
}

/**
 * Display Framework Question
 * @param index index value
 * @param row row data
 * @param answer selected answer yes or no
 * @param onAnswerChange handle to be called when answer switch is toggled
 * @returns 
 */
export default function FrameworkQuestion({
  index,
  row,
  answer,
  onAnswerChange,
}: FrameworkQuestionProps) {
  const { setAlerts } = useSnackBars();
  const [disabledIcon, setDisabledIcon] = useState(false);
  const [processDocName,setProcessDocName] = useState("");
  const [open, setOpen] = useState(false);

  //Downloads Process Document
  const onDownloadClick = (url:string, filename:string) => {
    const element = document.createElement("a");
    element.href = url;
    element.download = filename;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setDisabledIcon(false);
  }

  const [ getRoleTemplateURL ] = useLazyQuery(GetUrlDocument,{
    onCompleted: (RoleTemplateURLData) => {
      onDownloadClick(RoleTemplateURLData?.getTemplateS3URL, processDocName);
    },
    onError: () => {
      setDisabledIcon(false);
      setAlerts([
        {
          severity: "error",
          msg: "Failed to fetch the template. Please try again.",
        }
      ]);
    },
  });

  //Sets the correct file to download and calls the getRoleTemplateURL api
  const download = useCallback((question_id: string, template: string) => {
    const basePath = "process-documents/";
    setDisabledIcon(true);
    setProcessDocName(template);
    getRoleTemplateURL({
      variables: { template: `${basePath}${template}` },
    });

    // Toggle the switch for the current row
    const newValue = answer[0] === "yes" ? "no" : "yes";
    if (newValue !== "no"){
      onAnswerChange(question_id, newValue);
    }
  }, [setDisabledIcon, setProcessDocName, getRoleTemplateURL, onAnswerChange, answer]);
  
  //Handles the switch answer toggling from yes to no
  const handleSwitchToggle = useCallback(() => {
    const newAnswer = answer === "yes" ? "no" : "yes";
    onAnswerChange(row.question_id, newAnswer);
  }, [onAnswerChange, answer, row]);

  /**
   * @param row row
   * @returns Display Frameworks
   */
  const FrameworkDropDownDetails = ({
    row,
  }: {
    row: Row
  }) => (
    <Box sx={{ margin: 1, display: "block", paddingLeft: "5%" }}>
      {
        row.answers.map((answer: string) => {
          return(
            <Typography 
              key={answer}
              variant="body1"
              align="left"
              gutterBottom
              fontSize={"14px"}
            >
              {answer}
            </Typography>
          );
        })
      }
    </Box>
  );

  return (
    <>
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell component="th" scope="row">
        {index + 1}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row.question}
      </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <Switch
            color="success"
            checked={answer === "yes"}
            onChange={handleSwitchToggle}
            inputProps={{ "aria-label": `Toggle answer for question ${index + 1}` }}
          />
        </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        <IconButton 
          onClick={
            useCallback(() => download(row.question_id, row.template), [download])} 
          disabled={disabledIcon} 
          type="submit" 
          color="secondary"
        >
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        <IconButton color="secondary" onClick={() => setOpen(!open)}>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </IconButton>
      </StyledTableCell>
    </TableRow>
    <TableRow>
      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <FrameworkDropDownDetails
            key={row.question}
            row={row}
          />
        </Collapse>
      </StyledTableCell>
    </TableRow>
  </>
  );
}
